import {isCordova} from "~/cordova";

export const tenantErrorKey = 'tenantError';

export enum TenantCodes {
  dev = 'dev',
  dev2 = 'dev2',
  test = 'test',
  prod = 'prod',
}

export enum TenantErrorCodes {
  internalTenantErrorCode = 1001,
  externalTenantErrorCode = 1002
}

export type Tenant = {
  name: string
  baseUrl: string
  azureADB2CApplicationId?: string
  azureADB2CTenantId?: string
  azureADB2CAuthority?: string
  azureADB2CAuthorityPwdReset?: string
  azureADB2CScopes?: string
}

const { REACT_APP_ENV = '' } = process.env;
export const tenantCodeFromURL = (document.URL.indexOf('https://mm.barde.app') !== -1 || document.URL.indexOf('https://felt.barde.app') !== -1) ? TenantCodes.prod : ((document.URL.indexOf('https://mm-test.barde.app') !== -1 || document.URL.indexOf('https://felt-test.barde.app') !== -1) ? TenantCodes.test : ((document.URL.indexOf('https://mm-dev2.barde.app') !== -1 || document.URL.indexOf('https://felt-dev2.barde.app') !== -1) ? TenantCodes.dev2 : TenantCodes.dev));
export const tenantCode = isCordova ? TenantCodes[REACT_APP_ENV in TenantCodes ? REACT_APP_ENV : 'dev'] : tenantCodeFromURL;

export const tenantConfig: { [code in TenantCodes]: Tenant } = {
  [TenantCodes.dev]: {
    name: 'MM Dev',
    baseUrl: 'https://jwt-mm-backend.barde.app',
    azureADB2CApplicationId: "87ca6907-ac37-44bc-9dde-f6d05a510824",
    azureADB2CTenantId: "victum.b2clogin.com",
    azureADB2CAuthority: "https://victum.b2clogin.com/victum.onmicrosoft.com/B2C_1A_SignUpSignIn",
    azureADB2CAuthorityPwdReset: "https://victum.b2clogin.com/tfp/victum.onmicrosoft.com/B2C_1A_PasswordReset",
    azureADB2CScopes: "https://victum.onmicrosoft.com/auth/user_impersonation",
  },
  [TenantCodes.dev2]: {
    name: 'MM Dev2',
    baseUrl: 'https://mm-dev2-backend.barde.app',
    azureADB2CApplicationId: "87ca6907-ac37-44bc-9dde-f6d05a510824",
    azureADB2CTenantId: "victum.b2clogin.com",
    azureADB2CAuthority: "https://victum.b2clogin.com/victum.onmicrosoft.com/B2C_1A_SignUpSignIn",
    azureADB2CAuthorityPwdReset: "https://victum.b2clogin.com/tfp/victum.onmicrosoft.com/B2C_1A_PasswordReset",
    azureADB2CScopes: "https://victum.onmicrosoft.com/auth/user_impersonation",
  },
  [TenantCodes.test]: {
    name: 'MM Test',
    baseUrl: 'https://mm-test-backend.barde.app',
    azureADB2CApplicationId: "6dcf720a-ec91-4038-bb46-29dc9923788c",
    azureADB2CTenantId: "fleetmgmttest.b2clogin.com",
    azureADB2CAuthority: "https://fleetmgmttest.b2clogin.com/fleetmgmttest.onmicrosoft.com/B2C_1A_SignUpSignIn",
    azureADB2CAuthorityPwdReset: "https://fleetmgmttest.b2clogin.com/tfp/fleetmgmttest.onmicrosoft.com/B2C_1A_PasswordReset",
    azureADB2CScopes: "https://fleetmgmttest.onmicrosoft.com/auth/user_impersonation",
  },
  [TenantCodes.prod]: {
    name: 'MM Prod',
    baseUrl: 'https://mm-backend.barde.app',
    azureADB2CApplicationId: "dd8b42c8-5deb-4cbc-a6f0-0c989bfd3453",
    azureADB2CTenantId: "fleetmgmtprod.b2clogin.com",
    azureADB2CAuthority: "https://fleetmgmtprod.b2clogin.com/fleetmgmtprod.onmicrosoft.com/B2C_1A_SignUpSignIn",
    azureADB2CAuthorityPwdReset: "https://fleetmgmtprod.b2clogin.com/tfp/fleetmgmtprod.onmicrosoft.com/B2C_1A_PasswordReset",
    azureADB2CScopes: "https://fleetmgmtprod.onmicrosoft.com/auth/user_impersonation",
  },
};

export const publicApiURLs = [
  '/get_backend_url_from_tenant'
];